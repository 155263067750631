import { IDates, IGlobalQueryParams } from "../../interfaces/global.interfaces"
import { IProfile } from "../../models/auth/interfaces/auth.interfaces";
import { IClinic } from "../clinics/clinics.interfaces";
import { ICourier } from "../couriers/couriers.interfaces";
import { IDoctor } from "../doctors/doctors.interfaces"
import { IPatient } from "../patients/patients.interfaces"
import { ITechnician } from "../technicians/technicians.interfaces";
import { ChoiceSelectType, FormType, InputType, OrderStatus, TeethChoiceSelectType } from "./orders.enums"


export enum OrderStatusTabsTitle {
    All = 'All',
    Drafts = 'Drafts',
    Pending = 'Pending',
    InProgress = 'In progress',
    ProductCompleted = 'Production completed',
    Delivering = 'Delivering',
    Delivered = 'Delivered',
    Completed = 'Completed',
    WaitingForCorrection = 'Sent for correction',
    Canceled = 'Canceled'
}

export enum OrderStateTabsTitle {
    All = 'All',
    Drafts = 'Drafts',
    InProgress = 'In progress',
    Pending = 'Pending',
    Completed = 'Completed',
    Canceled = 'Canceled'
}

export enum OrdersPageTitle {
    All = 'All orders',
    Drafts = 'Draft orders',
    InProgress = 'Orders in progress',
    Pending = 'Pending orders',
    Completed = 'Completed orders',
    Canceled = 'Canceled orders'
}

export enum OrderState {
    All = 'All',
    Drafts = 'Drafts',
    InProgress = 'InProgress',
    Pending = 'Pending',
    Completed = 'Completed',
    Canceled = 'Canceled'
}

export interface IOrderStatusTabs {
    title: OrderStatusTabsTitle,
    statuses?: OrderStatus[] | undefined;
    id: number,
    count: number | undefined
}


export enum OrderServiceTeethType {
    default = 'default',
    pointic = 'pointic'
}
export interface IOrderServiceTeeth {
    id: number,
    type: OrderServiceTeethType
}
export interface OrderServiceForms {
    form: IForm[],
    formCompleted: boolean,
    formSubmitted: boolean,
    id: number,
    teeth: IOrderServiceTeeth[],
    orderServiceTechnicians: formTechnicians[],
    // CUSTOM FE ADDITIONS
    displayTeethUI?: any[],
    hasSelectedUpperJaw?: boolean,
    hasSelectedLowerJaw?: boolean,
    hasSelectedBothJaws?: boolean,
    selected?: boolean,
    progressInPercent?: number,
    completedTechnicians?: number,
    //technician only :
    currentTechnicianAccountCompleted?: boolean

}

export interface formTechnicians {
    formId: number,
    formCompleted: boolean,
    formCompletedAt: string,
    technician: ITechnician
}
export interface IOrderServices {
    id: number,
    service: IOrderService,
    forms: OrderServiceForms[],
    externalDocuments: IExternalDocument[],
    teeth: IOrderServiceTeeth[],
    technicians: formTechnicians[],
    // CUSTOM FE ADDITIONS
    displayTeethUI?: any[],
    hasSelectedUpperJaw?: boolean,
    hasSelectedLowerJaw?: boolean,
    hasSelectedBothJaws?: boolean,
    completed?: boolean,
    inProgress?: boolean,
    completedForms?: number,
    progressInPercent?: number,
}

export interface IExternalDocumentsFile {
    fileName: string,
    id: number,
    url: string;
}

export interface IExternalDocumentsUrl {
    fileName: string,
    id: number,
    url: string
}
export interface IExternalDocument {
    forms: IForm[],
    id: number,
    images: IExternalDocumentsFile[],
    orderExternalDocumentUrls: IExternalDocumentsUrl[],
    scans: IExternalDocumentsFile[],
    threeDImages: IExternalDocumentsFile[],
}

export interface IOrderService {
    id: number,
    label: string,
    code: string,
    nameLangCode: string,
    colorCode: string,
    enabled: boolean,
    active: boolean,
    teethChoiceSelectionType: TeethChoiceSelectType,
    startingFormId: number,
    formType: FormType,
    forms: IForm[];
    pointicColorCode?: string;
}

export interface Choice {
    id: number;
    label: string;
    type: InputType;
    labelLangCode: string;
    nextFormId?: number;
    code: string;
    inputs?: Input[];
}

export interface Input {
    id: number;
    label: string;
    type: InputType;
    labelLangCode: string;
    name: string;
    placeholder?: string;
    placeholderLangCode?: string;
    validation?: {
        required: boolean;
    };
    optionValues?: OptionValue[];
    code: string;
    uploadType?: string;
    clearable?: boolean;
    acceptedFileTypes?: string[];
    fileLimit?: number;
    choicesInline?: boolean;
    actions?: Action[];
    value: any;
}

export interface OptionValue {
    id: number;
    label: string;
    code: string;
}

export interface Action {
    id: number;
    label: string;
    labelLangCode: string;
    type: InputType;
    buttonType?: string;
    acceptedFileTypes?: string[];
    nextFormId?: number;
    fileLimit?: number;
    code: string;
}

export interface IForm {
    id: number;
    label: string;
    labelLangCode: string;
    teethChoiceSelectType?: TeethChoiceSelectType;
    colorCode: string;
    nextFormId?: number;
    code: string;
    choiceSelectType?: ChoiceSelectType;
    choices?: Choice[];
    inputs?: Input[];
    actions?: Action[];
    note?: string;
    formType?: FormType;
    form?: IForm[];
}

export interface IOrderStatusHistory {
    createdAt: string,
    status: OrderStatus
}

export interface DeliveryOption {
    deliveryDate: string,
    id: number,
    label: string,
    percent: number,
}

export interface OrderReturn {
    returning: boolean,
    returned: boolean
}

export interface OrderCounts {
    totalForms: number,
    totalSubmittedForms: number,
    totalAssignedForms: number,
    totalCompletedForms: number
}

export interface OrderDelivery {
    dropoffDate: string,
    courier: ICourier,
    deliveryStart: string,
    deliveryEnd: string,
    delivered: boolean,
    markedAsNotDelivered: boolean
}
export interface OrderResubmitItem extends IDates {
    id: number,
    method: string,
    returnAddress: string,
    dropoffDate: string,
    delivery: DeliveryOption,
    reasons: {comment: string, id: number, label: string}[],
    orderPackageReturn: OrderDelivery

}

export interface IOrder extends IDates {
    referenceNumber: string,
    hasUpdates: boolean,
    hasCourier: boolean,
    hasCourierId: boolean,
    counts: OrderCounts,
    id: number,
    status: OrderStatus,
    delivery: DeliveryOption,
    orderDelivery: OrderDelivery,
    clinic: IClinic,
    doctor: IDoctor,
    patient: IPatient,
    orderServices: IOrderServices[],
    statusHistory: IOrderStatusHistory[],
    additionalDetails: string,
    resubmitData: OrderResubmitItem[],
    // CUSTOM FE ADDITIONS BELOW
    latestResubmit: OrderResubmitItem,
    technicians?: formTechnicians[],
    uniqueTechnicians?: formTechnicians[],
    completedOrderServices?: number,
    productionCompleted?: boolean,
    progressInPercent?: number,
    completedForms?: number,
    // technicianOnly 
    assignedTechnicianForms?: number,
    completedTechnicianForms?: number,

}

export interface IPatientsParams extends IGlobalQueryParams {
    clinicId?: string | null,
    doctorId?: string | null
}


export enum PatientsParamsField  {
    clinicId = 'clinicId',
    search = 'search',
    clinicsSearch = 'clinicsSearch',
    doctorId = 'doctorId',
    doctorsSearch = 'doctorsSearch'
  }
  


export interface IAssignTechnicianBody {
    orderId: number,
    serviceId: number,
    technicianId: number,
    formIds: number[]
}

export interface ICompleteFormBody {
    orderId: number,
    serviceId: number,
    technicianId: number,
    formId: number,
    completed: boolean
}

export interface IUpdateOrderPatient {
    patient: number
}